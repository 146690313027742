import React from 'react';


function Dashboard() {
    
    return (
        <div className="min-h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-400 text-white p-10">
            <h1>Hola</h1>
        </div>
    );
}

export default Dashboard;
