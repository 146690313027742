import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-pastel-blue-300 text-gray-800">
      <Header handleScroll={handleScroll} />

      <main className="flex-grow">
        <div className="container mx-auto px-4 py-10">
          <section id="introduction" className="text-center">
            <img src="/logo.png" alt="PetitProjectHub Logo" className="mx-auto h-32 sm:h-40 md:h-48 lg:h-56" />
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">Bienvenido a PetitProjectHub</h1>
            <p className="text-xl mt-3">
              Un lugar donde impulsamos el crecimiento de pequeños comercios mediante soluciones digitales innovadoras.
            </p>
          </section>

          <section id="projects" className="mt-10">
            <h2 className="text-4xl font-bold text-center">Nuestros Proyectos</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-5">
              <ProjectCard
                title="PetitStores"
                description="Plataforma de comercios que permite a negocios crear su propia estrategia de fidelización de clientes de manera sencilla y gratuita."
                link="https://petitstores.com"
                imgSrc="/assets/petitstores.svg"
              />
              <ProjectCard
                title="PetitFeedback"
                description="Herramienta de recopilación de feedback para entender mejor las necesidades y preferencias de tus clientes."
                link="https://petitfeedback.com"
                imgSrc="/assets/petitfeedback.png"
              />
            </div>
          </section>

          <section id="mission" className="mt-10 text-center">
            <h2 className="text-4xl font-bold">Nuestra Misión</h2>
            <p className="text-xl mt-4">
              En medio de la crisis global del COVID-19, un momento de dudas sobre la continuidad de muchos comercios y un crecimiento del trabajo y la vida remota, nació PetitProjectHub. Conscientes de los desafíos a los que se enfrentaban los pequeños y medianos comercios, nos propusimos una misión clara: transformar la adversidad en oportunidad. Nos dedicamos a desarrollar la suite más completa y accesible de herramientas digitales, destinadas a empoderar a los negocios locales en su transición hacia el mundo digital.            </p>
            <p className="text-xl mt-2">
                Creemos que cada comercio tiene el potencial de expandirse más allá de sus fronteras físicas. Por eso, PetitProjectHub facilita tecnologías sencillas pero poderosas, diseñadas para democratizar el acceso a soluciones digitales, permitiendo a nuestros clientes competir en un mercado globalizado sin la necesidad de grandes inversiones. Nuestra visión es clara: hacer que la digitalización sea no solo posible, sino también económica y sencilla para cada pequeño comerciante que sueña con crecer.
            </p>
        </section>


        <section id="contact" className="mt-10 text-center">
          <h2 className="text-4xl font-bold mt-4">Contacto</h2>
          <p className="text-xl mt-4">
              ¿Tienes preguntas? Puedes contactarnos a través de <a href="mailto:info@petitstores.com" className="text-blue-600">info@petitprojecthub.com</a> o sigue nuestra actividad y novedades en Instagram: <a href="https://www.instagram.com/petitstores_official" className="text-blue-600">@petitstores_official</a>.
          </p>
      </section>

        </div>
      </main>

      <Footer />
    </div>
  );
}

function Header({ handleScroll }) {
  return (
    <nav className="bg-pastel-pink-500 p-4 font-bold text-lg">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-3xl text-dark-900">PetitProjectHub</h1>
        <div>
          <button onClick={() => handleScroll('introduction')} className="mx-2 text-dark-900 hover:text-gray-800">Inicio</button>
          <button onClick={() => handleScroll('projects')} className="mx-2 text-dark-900 hover:text-gray-800">Proyectos</button>
          <button onClick={() => handleScroll('mission')} className="mx-2 text-dark-900 hover:text-gray-800">Misión</button>
          <button onClick={() => handleScroll('contact')} className="mx-2 text-dark-900 hover:text-gray-800">Contacto</button>
        </div>
      </div>
    </nav>
  );
}

function Footer() {
  return (
    <footer className="bg-pastel-green-500 p-4 text-center text-white">
      <p>© 2024 PetitProjectHub. Todos los derechos reservados.</p>
    </footer>
  );
}

function ProjectCard({ title, description, link, imgSrc }) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-6 hover:scale-105 transition-transform">
      <h2 className="text-2xl font-bold">{title}</h2>
      <p>{description}</p>
      <div className="flex justify-center mt-4"> 
        <Link to={link} className="inline-block bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-full">
          Visitar
        </Link>
      </div>
      <div className="mt-4">
        <img src={imgSrc} alt={`${title} logo`} className="h-40 mx-auto" />
      </div>
    </div>
  );
}


export default Home;
