// authStore.js
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(persist((set) => ({
    user: null,
    token: null,
    isAuthenticated: false,
    setUser: (userData) => set({
        user: {
            userId: userData.userId,
            // You can add more user details here if your response includes them
        },
        token: userData.token,
        isAuthenticated: true
    }),
    logout: () => set({
        user: null,
        token: null,
        isAuthenticated: false
    })
}), {
    name: 'auth-storage',  // The key in localStorage
    getStorage: () => localStorage  // Specify localStorage as the storage location
}));

export default useAuthStore;
