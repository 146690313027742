import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { get, post } from '../helpers/apiHelper';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('españa');
  const [priceConfig, setPriceConfig] = useState({});
  const [price, setPrice] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar la configuración de precios al iniciar el componente
    const fetchPriceConfig = async () => {
      try {
        const config = await get('auth/price-config');
        setPriceConfig(config);
        setPrice(config['españa'].price / 100); // Ejemplo inicial
      } catch (error) {
        console.error('Failed to fetch price configuration:', error);
        Swal.fire('Error', 'Failed to load price configuration', 'error');
      }
    };

    fetchPriceConfig();
  }, []);

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      console.log('[error]', error);
      Swal.fire('Error', error.message, 'error');
      return;
    }

    const paymentToken = paymentMethod.id;

    if (password !== confirmPassword) {
      Swal.fire('Error', 'Las contraseñas no coinciden!', 'error');
      return;
    }

    const registerData = {
      email,
      password,
      country,
      paymentToken
    };

    try {
      const response = await post('auth/register', registerData);
      if (response.paymentIntentId) {
        // Redirigir al usuario a la página de resultado de pago con el ID del PaymentIntent
        navigate(`/payment-result?payment_intent=${response.paymentIntentId}&payment_intent_client_secret=${response.paymentIntentClientSecret}`);
      } else {
        Swal.fire('Success', 'Registration successful!', 'success');
        navigate('/dashboard');
      }
    } catch (error) {
      Swal.fire('Error', `Registration failed. ${error.message}`, 'error');
    }
  };

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
    const newPrice = priceConfig[newCountry.toLowerCase()];
    if (newPrice) {
      setPrice(newPrice.price / 100);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-400 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold">Crea una cuenta</h2>
        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />
          <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirma Contraseña" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />
          <select onChange={handleCountryChange} required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
            <option value="españa">España</option>
            <option value="perú">Peru</option>
            <option value="chile">Chile</option>
          </select>
          <CardElement options={{ hidePostalCode: true }} className="p-2 bg-white text-gray-700 rounded border"/>
          <button type="submit" disabled={!stripe} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Pagar €{price} y Registrarse
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;

