import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-xl font-bold text-center">Términos y Condiciones de Uso</h1>
            <p className="mt-4">
                Bienvenido a PetitFeedback
            </p>
            <p>
                Estos términos y condiciones describen las reglas y regulaciones para el uso de PetitFeedback, ubicada en https://petitfeedback.com.
            </p>
            <h2 className="mt-6 text-lg font-semibold">Licencia</h2>
            <p>
                A menos que se indique lo contrario, PetitHubProjects y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en PetitFeedback. Todos los derechos de propiedad intelectual son reservados. Puedes acceder a esto desde [Nombre de la Aplicación] para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.
            </p>
            <h2 className="mt-6 text-lg font-semibold">Recopilación de Datos</h2>
            <p>
                Recopilamos varios tipos de información para varios propósitos para proporcionar y mejorar nuestro servicio a ti. Los tipos de datos recopilados incluyen:
                <ul className="list-disc ml-8">
                    <li>Datos Personales mientras usas nuestro servicio, podemos pedirte que nos proporciones cierta información personalmente identificable que puede ser usada para contactarte o identificarte.</li>
                    <li>Datos de Uso también podemos recopilar información sobre cómo se accede y se utiliza el servicio.</li>
                </ul>
            </p>
            <h2 className="mt-6 text-lg font-semibold">Uso de Datos</h2>
            <p>
                PetitHubProjects utiliza los datos recopilados para varios propósitos:
                <ul className="list-disc ml-8">
                    <li>Proveer y mantener nuestro servicio</li>
                    <li>Notificarle sobre cambios en nuestro servicio</li>
                    <li>Permitirle participar en funciones interactivas de nuestro servicio cuando elija hacerlo</li>
                    <li>Proveer soporte al cliente</li>
                    <li>Recopilar análisis o información valiosa para que podamos mejorar nuestro servicio</li>
                </ul>
            </p>
            <h2 className="mt-6 text-lg font-semibold">Consentimiento</h2>
            <p>
                Al utilizar nuestra aplicación, usted aquí consiente el uso de sus datos en acuerdo con esta política.
            </p>
            <h2 className="mt-6 text-lg font-semibold">Seguridad de Datos</h2>
            <p>
                La seguridad de tus datos es importante para nosotros, pero recuerda que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Mientras nos esforzamos por usar medios comercialmente aceptables para proteger tus datos personales, no podemos garantizar su seguridad absoluta.
            </p>
            <h2 className="mt-6 text-lg font-semibold">Modificaciones</h2>
            <p>
                Nos reservamos el derecho de modificar o reemplazar estos Términos en cualquier momento. Lo que constituye un cambio material será determinado a nuestra discreción.
            </p>
            <h2 className="mt-6 text-lg font-semibold">Contacto</h2>
            <p>
                Si tienes alguna pregunta sobre estos Términos, por favor contacta a:
                <ul className="list-disc ml-8">
                    <li>Email: info@petitstores.com</li>
                </ul>
            </p>
        </div>
    );
}

export default TermsAndConditions;
