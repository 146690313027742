import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { get } from '../helpers/apiHelper';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress'; // Asegúrate de tener @mui/material instalado

function PaymentResult() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get('payment_intent');
  const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
  
  useEffect(() => {
    if (!paymentIntentId || !paymentIntentClientSecret) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo obtener la información del pago.',
        confirmButtonColor: '#dc3545',
      }).then(() => {
        navigate('/'); // Redirigir a inicio o a la página de error
      });
      return;
    }

    // Llamar a la API para verificar el estado del pago
    get(`payments/verify/${paymentIntentId}`)
      .then(response => {
        if (response.status === 'succeeded') {
          Swal.fire({
            icon: 'success',
            title: 'Pago exitoso',
            text: 'Su pago ha sido procesado con éxito!',
            confirmButtonColor: '#3085d6',
          }).then(() => {
            navigate('/dashboard'); // Redirigir al dashboard o a la página de éxito
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Pago fallido',
            text: 'El pago no se pudo procesar. Por favor, intente nuevamente.',
            confirmButtonColor: '#dc3545',
          }).then(() => {
            navigate('/'); // Redirigir a inicio o a la página de reintento
          });
        }
      })
      .catch(error => {
        console.error('Error al verificar el estado del pago:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error de verificación',
          text: 'Hubo un problema al verificar el estado de su pago. Intente de nuevo más tarde.',
          confirmButtonColor: '#dc3545',
        }).then(() => {
          navigate('/'); // Redirigir a inicio o a la página de error
        });
      });
  }, [navigate, paymentIntentId, paymentIntentClientSecret]);

  return (
    <div className="flex justify-center items-center flex-col min-h-screen">
      <h1 className="text-xl mb-5">Verificando su pago...</h1>
      <CircularProgress color="inherit" /> {/* Spinner de carga */}
    </div>
  );
}

export default PaymentResult;
