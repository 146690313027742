import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePolicyPage from './pages/CookiePolicyPage';
import PaymentResult from './pages/PaymentResult';
import Settings from './pages/Settings';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';

function App() {
  return (
    <Router>
      {/*<Header />*/}
          <Routes>
            {/* Envoltura para rutas publicas */}
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset-password" element={<ResetPassword/>} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>

            {/* Envoltura para rutas protegidas */}
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings/>} />
              {/* Otros rutas protegidas */}
            </Route>

            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />        
            {/* Redirige cualquier ruta desconocida al inicio */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>

     {/* <Footer /> */}
    </Router>
  );
}

export default App;
