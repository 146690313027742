// src/components/PublicRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../store/authStore';

function PublicRoute() {
  const user = useAuthStore(state => state.user);

  return user ? <Navigate to="/dashboard" /> : <Outlet />;
}

export default PublicRoute;
